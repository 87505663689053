.location-primary {
    position: relative;
    min-width: 250px;
}

.location-secondary {
    position: absolute;
    font-size: 11px;
    font-weight: normal;
    margin-left: 28px;
    opacity: 0.5;
    letter-spacing: 0.1em;
}

.service.bg-secondary {
    background-color: rgba(31, 39, 73, 0.7) !important;
}

.service {
    text-align: left;
}

@media (min-width:0px) and (max-width: 543.98px) {
    .service-spacer {
        margin-top: 0vh;
    }

    .location-secondary {
        top: 20px;
    }
    
    .guy {
        margin-top: 60px;
    }

    .guy-question {
        font-size: 24px;
    }

    .guy-description {
        font-size: 14px;
    }
    
    .guy-callout {
        font-size: 20px;
    }
}

@media (min-width:544px) and (max-width: 767.98px) {
    .navbar {
        background-color: rgba(31,39,73,0.9) !important;
    }

    .service-spacer {
        margin-top: -8vh;
    }

    .location-secondary {
        top: 20px;
    }
}

@media (min-width:768px) and (max-width: 991.98px) {
    .navbar {
        background-color: rgba(31,39,73,0.9) !important;
    }

    .service-spacer {
        margin-top: -10vh;
    }

    .location-secondary {
        top: 20px;
    }
}

@media (min-width:992px) and (max-width: 1199.98px) {
    .navbar {
        background-color: rgba(31,39,73,0.9) !important;
    }

    .service-spacer {
        margin-top: -23vh;
    }
}

@media (min-width:1200px) {
    .navbar {
        background-color: rgba(31,39,73,0.9) !important;
    }

    .service-spacer {
        margin-top: -12vh;
    }
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) { 
    .navbar {
        background-color: rgba(31,39,73,0.9) !important;
    }

    .service-spacer {
        margin-top: 0vh;
    }
}